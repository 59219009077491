import React, {useState} from "react"
import ShapePlayground from '../components/shapePlayground'
import Layout from "../layouts/index"
import Seo from "../components/seo"
import '../styles/styles.scss'


const TestPage = (props) => {
    const [count, setCount] = useState(0);
    function addOne() {
        setCount(count + 1);
    };

    return(
        <>
            <Seo title="Home" />
            <ShapePlayground/>
        </>
    )
}

export default TestPage
